import axios, { AxiosHeaderValue } from "axios";
import { useEffect, useState } from "react";
import clusterMasterResultType from "../model/ClusterMasterResultType";
import { Button, Col, Form, Row } from "react-bootstrap";
import Result from "../components/ClusterMasterResult";
import Header from "../components/Header";
import PageSelect from "../components/PageSelect";
import ExcelJS from 'exceljs';
import Loading from "../components/Loading";

const ClusterMaster = () => {

    const [apiKey, setApikey] = useState<string>("");
    const [id, setId] = useState<string>("");
    const [per_page, setPer_Page] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [parent_id, setParent_id] = useState<string>("");
    const [tag, setTag] = useState<string>("");
    const [cm_per_pixel, setCmPer] = useState<string>("");
    const [att_coef, setAtt] = useState<string>("");
    const [enabled, setEnabled] = useState<string>("");
    const [created_gt, setCreated_gt] = useState<string>("");
    const [created_lt, setCreated_lt] = useState<string>("");
    const [updated_gt, setUpdated_gt] = useState<string>("");
    const [updated_lt, setUpdated_lt] = useState<string>("");
    const [sort, setSort] = useState<string>("");
    const [pageNo, setPageNo] = useState<number>(0);
    const [lastPageNo, setLastPageNo] = useState<number>(0);
    const [total, setTotal] = useState<number>(-1);
    const [result, setResult] = useState<clusterMasterResultType[]>([]);
    const results: clusterMasterResultType[] = [];
    const [csvResult, setCSVResult] = useState<clusterMasterResultType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isNoCheck, setNoCheck] = useState<boolean>(false);
    var apiUrl:string = "";

    const checkLocoEnv = () => {
      if (localStorage.getItem("locoEnv") === "prod") {
        apiUrl = "https://api.bc-loco.jp/api/clusters"
      } else {
        apiUrl = "http://stg.api.bc-loco.jp/api/clusters"
      }
    }
    
    const getCluster = async(e: any, pageNo: number) => {
        e.preventDefault();
        saveParams();
        saveApikey();
        checkLocoEnv();
        await axios.get(apiUrl,
        {
          headers: {
              "Authorization": apiKey,
              "Content-Type": "application/json"
          },
          params: {
              ...(id ? {id: id} : {}),
              ...(pageNo ? {page: pageNo} : {}),
              ...(per_page ? {per_page: per_page} : {}),
              ...(name ? {name: name} : {}),
              ...(parent_id ? {parent_id: parent_id} : {}),
              ...(tag ? {tag: tag} : {}),
              ...(cm_per_pixel ? {cm_per_pixel: cm_per_pixel} : {}),
              ...(att_coef ? {att_coef: att_coef} : {}),
              ...(enabled ? {enabled: enabled} : {}),
              ...(created_gt ? {created_gt: created_gt.replace("T", " ")} : {}),
              ...(created_lt ? {created_lt: created_lt.replace("T", " ")} : {}),
              ...(updated_gt ? {updated_gt: updated_gt.replace("T", " ")} : {}),
              ...(updated_lt ? {updated_lt: updated_lt.replace("T", " ")} : {}),
              ...(sort ? {sort: sort} : {})
          }
        })
        .then(async res => {
          console.log(res);
          if(res.data.code === 400) {
            alert("条件設定に問題があります。" + JSON.stringify(res.data.payload))
            return
          } else if(res.data.code === 401) {
            alert("APIKeyが正しく入力されていません。" + JSON.stringify(res.data.message))
            return
          }
          const items = JSON.parse(JSON.stringify(res.data.payload))
          if (items.total > 100000) {
            alert("10万件を超えるデータは表示出来ません。 総件数:" + items.total + "\n日付範囲を調整して下さい。")
            return 
          }
          setTotal(items.total)
          setPageNo(items.current_page)
          setLastPageNo(items.last_page)
          
          items.data.forEach((value: clusterMasterResultType, index: number) => {
            
            let beacons: string[] = []
            value.beacons.forEach((beacon: any , beaocon_index: number) => {
                beacons[beaocon_index] = "id: " + beacon.id
            })
        

            let resultbuf: clusterMasterResultType = {
                no: 0,
                id: value.id,
                name: value.name,
                tag: value.tag,
                image: value.image,
                cm_per_pixel: value.cm_per_pixel,
                att_coef: value.att_coef,
                parent_id: value.parent_id,
                beacons: beacons,
                created: value.created,
                updated: value.updated,
                enabled: value.enabled
            }
           
            results.push(resultbuf)
            
            console.log("beacons:" + results[index].beacons[0])
            results[index].no = items.from;
            items.from++;
          })
          setResult(results)
          
        })
        .catch(err => 
          alert("エラーが発生しました。" + err)
        )
    }
    const createCSV = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {

      
      const workbook = new ExcelJS.Workbook();
      workbook.addWorksheet("ClusterMaster");
      const worksheet = workbook.getWorksheet("ClusterMaster");
      const columns: Partial<ExcelJS.Column>[] = [];

      if (isNoCheck) {
        columns.push({ header: "no", key: "no" }) 
      }
      columns.push({ header: "id", key: "id" })
      columns.push({ header: "name", key: "name" })
      columns.push({ header: "tag", key: "tag" })
      columns.push({ header: "image", key: "image" })
      columns.push({ header: "cm_per_pixel", key: "cm_per_pixel" })
      columns.push({ header: "att_coef", key: "att_coef" })
      columns.push({ header: "parent_id", key: "parent_id" })
      columns.push({ header: "beacons", key: "beacons" })
      columns.push({ header: "created", key: "created" })
      columns.push({ header: "updated", key: "updated" })
      columns.push({ header: "enabled", key: "enabled" })


      worksheet.columns = columns;
      setIsLoading(true);
      if (await getAllCluster(e, 1) == true ) {
      
        
        csvResult.forEach((value: clusterMasterResultType) => {
          worksheet.addRow(value);
        });

        csvResult.length = 0;

        console.log("Row Count:" + worksheet.rowCount)
        setIsLoading(false);

        try {
          const uint8Array = await workbook.csv.writeBuffer();
          const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          setTimeout(()=> {
            a.href = url;
            a.download = "clusterMaster_" + getNowDate() +  ".csv";
            a.click();
            a.remove();
          },1000)

        } catch (error) {
          console.error(error);
        }
      } else {
        setIsLoading(false);
      }
    }

    const getAllCluster = async(e: any, pageNo: number) => {
      let ret = true;
      e.preventDefault();
      checkLocoEnv();
      await axios.get(apiUrl,
      {
        headers: {
            "Authorization": apiKey,
            "Content-Type": "application/json"
        },
        params: {
            ...(id ? {id: id} : {}),
            ...(pageNo ? {page: pageNo} : {}),
            ...({per_page: 1000}),
            ...(name ? {name: name} : {}),
            ...(parent_id ? {parent_id: parent_id} : {}),
            ...(tag ? {tag: tag} : {}),
            ...(cm_per_pixel ? {cm_per_pixel: cm_per_pixel} : {}),
            ...(att_coef ? {att_coef: att_coef} : {}),
            ...(enabled ? {enabled: enabled} : {}),
            ...(created_gt ? {created_gt: created_gt.replace("T", " ")} : {}),
            ...(created_lt ? {created_lt: created_lt.replace("T", " ")} : {}),
            ...(updated_gt ? {updated_gt: updated_gt.replace("T", " ")} : {}),
            ...(updated_lt ? {updated_lt: updated_lt.replace("T", " ")} : {}),
            ...(sort ? {sort: sort} : {})
        }
      })
      .then(async res => {
        console.log(res);
        if(res.data.code === 400) {
          alert("条件設定に問題があります。" + JSON.stringify(res.data.payload))
          return ret = false
        } else if(res.data.code === 401) {
          alert("APIKeyが正しく入力されていません。" + JSON.stringify(res.data.message))
          return ret = false
        }
        const items = JSON.parse(JSON.stringify(res.data.payload))
        if (items.total > 100000) {
          alert("10万件を超えるデータは出力出来ません。 総件数:" + items.total + "\n日付範囲を調整して下さい。")
          return ret = false
        }
        let csvArrayCount = csvResult.length;
        
        items.data.forEach((value: clusterMasterResultType, index: number) => {

          let beacons: string[] = []
            value.beacons.forEach((beacon: any , beaocon_index: number) => {
                beacons[beaocon_index] = "id: " + beacon.id
            })

            let resultbuf: clusterMasterResultType = {
                no: 0,
                id: value.id,
                name: value.name,
                tag: value.tag,
                image: value.image,
                cm_per_pixel: value.cm_per_pixel,
                att_coef: value.att_coef,
                parent_id: value.parent_id,
                beacons: beacons,
                created: value.created,
                updated: value.updated,
                enabled: value.enabled
            }
          csvResult.push(resultbuf);
          csvResult[index + csvArrayCount].no = items.from;
          items.from++;
        })
        setCSVResult(csvResult);
        if (items.current_page != items.last_page) {
          await getAllCluster(e, items.current_page + 1);
        } else {
          console.log("getall: " + csvResult.length);
        }
      })
      .catch(err => 
        alert("エラーが発生しました。" + err)
      )
      return ret
  }

  const changeCreatedGt = (value: string) => {
    if (value.length >= 1 && value.length < 17) {
      value = value + ":00";
    }
    setCreated_gt(value);
  }

  const changeCreatedLt = (value: string) => {
    if (value.length >= 1 && value.length < 17) {
      value = value + ":00";
    }
    setCreated_lt(value);
  }

  const changeUpdateGt = (value: string) => {
    if (value.length >= 1 && value.length < 17) {
      value = value + ":00";
    }
    setUpdated_gt(value);
  }

  const changeUpdateLt = (value: string) => {
    if (value.length >= 1 && value.length < 17) {
      value = value + ":00";
    }
    setUpdated_lt(value);
  }

  const getNowDate = () => {
    let dt = new Date();
    let y = dt.getFullYear();
    let m = ("00" + (dt.getMonth()+1)).slice(-2);
    let d = ("00" + (dt.getDate())).slice(-2);
    let result = y  + m +  d;
    return result;
  }

  type clusterParamsType = {
    id?: string,
    perpage?: string,
    page?: number,
    name?: string,
    parent_id?: string,
    tag?: string,
    image?: string,
    cm_per_pixel?: string,
    att_coef?: string,
    enabled?: string,
    created_gt?: string,
    created_lt?: string,
    updated_gt?: string,
    updated_lt?: string,
    sort?: string
  }

  const saveParams = () => {
    let params: clusterParamsType = {};
    params.id = id ? id : "";
    params.perpage = per_page ? per_page : "";
    params.name = name ? name : "";
    params.parent_id = parent_id ? parent_id : "";
    params.tag = tag ? tag : "";
    params.cm_per_pixel = cm_per_pixel ? cm_per_pixel : "";
    params.att_coef = att_coef ? att_coef : "";
    params.enabled = enabled ? enabled : "";
    params.created_gt = created_gt ? created_gt : "";
    params.created_lt = created_lt ? created_lt : "";
    params.updated_gt = updated_gt ? updated_gt : "";
    params.updated_lt = updated_lt ? updated_lt : "";
    params.sort = sort ? sort : "";

    let json = JSON.stringify(params);
    console.log("params:" + json);
    localStorage.setItem("locoClusterMasterParams", json)

  }
  const saveApikey = () => {
    localStorage.setItem("locoApikey", apiKey ? apiKey : "" );
  }

  const loadParams = () => {
    const paramsJson = localStorage.getItem("locoClusterMasterParams")
    if (paramsJson != null) {
      const params:clusterParamsType = JSON.parse(paramsJson as string)
      setId(params.id ? params.id : "");
      setPer_Page(params.perpage ? params.perpage : "");
      setName(params.name ? params.name : "");
      setParent_id(params.parent_id ? params.parent_id : "");
      setTag(params.tag ? params.tag : "");
      setCmPer(params.cm_per_pixel ? params.cm_per_pixel : "");
      setAtt(params.att_coef ? params.att_coef : "");
      setEnabled(params.enabled ? params.enabled : "");
      setCreated_gt(params.created_gt ? params.created_gt : "");
      setCreated_lt(params.created_lt ? params.created_lt : "");
      setUpdated_gt(params.updated_gt ? params.updated_gt : "");
      setUpdated_lt(params.updated_lt ? params.updated_lt : "");
      setSort(params.sort ? params.sort : "");
    }
  }

  const loadApikey = () => {
    const locoApikey = localStorage.getItem("locoApikey")
    if (locoApikey != null) {
      setApikey(locoApikey ? locoApikey : "");
    }
  }

  const setSelectApikey = () => {
    const secretJson = localStorage.getItem("locoSecretArray")
    if (secretJson != null) {
      const secret = JSON.parse(secretJson as string)

      const selectApikey = document.getElementById("apikey") as HTMLSelectElement;
      const option1 = document.createElement("option");
      option1.value = "";
      option1.textContent = "APIKeyを選択して下さい";
      selectApikey?.appendChild(option1)

      secret.forEach((element: { apikey: string; name: string | null; }) => {
          const option1 = document.createElement("option");
          option1.value = element.apikey;
          option1.textContent = element.name;
          selectApikey?.appendChild(option1)
      })
    }
  }


  useEffect(() => {
    const secretJson = localStorage.getItem("locoSecretArray")
    if (secretJson != null) {
      setSelectApikey()
    }
    loadParams();
    loadApikey();
      
  },[])

  return (
    <div>
    <Header current_page="ClusterMaster"/>
    <div className="card">
      <Form className="font-weight-bold" autoComplete="on" onSubmit={(e) => getCluster(e, 0)}>
        
        <Form.Group as={Row} className="mx-3">
          <Col xs={12} md={4}>
            <Form.Label>ApiKey</Form.Label>
            <Form.Control as="select" id="apikey" value={apiKey as string} placeholder="ApiKeyを入力" onChange={(e) => setApikey(e.target.value)} />
          </Col>
          <Col xs={12} md={4}>
            <Form.Label>Per_Page（デフォルト5件/最大1000件）</Form.Label>
            <Form.Control type="text" name="per_page" value={per_page as string}  placeholder="１ページ辺りの最大件数を指定" onChange={(e) => setPer_Page(e.target.value)} />
          </Col>
          <Col xs={12} md={4}>
            <Form.Label>Id</Form.Label>
            <Form.Control type="text" name="Id" value={id as string}  placeholder="Idを入力" onChange={(e) => setId(e.target.value)} />
          </Col>
          
        </Form.Group>
        
        <Form.Group as={Row} className="mx-3">
          <Col xs={12} md={4}>
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" name="Name" value={name as string} className="w-27" placeholder="Nameを入力" onChange={(e) => setName(e.target.value)} />
          </Col>
          <Col xs={12} md={4}>
            <Form.Label>親クラスタのID</Form.Label>
            <Form.Control type="text" name="parent_id" value={parent_id as string} className="w-27" placeholder="親クラスタのIDを入力" onChange={(e) => setParent_id(e.target.value)} />
          </Col>
          <Col xs={12} md={4}>
            <Form.Label>Tag</Form.Label>
            <Form.Control type="text" name="tag" value={tag as string} className="w-27" placeholder="Tagを入力" onChange={(e) => setTag(e.target.value)} />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mx-3">
          <Col xs={12} md={4}>
            <Form.Label>Cm_Per_Pixel</Form.Label>
            <Form.Control type="text" name="cm_per_pixel" value={cm_per_pixel as string} className="w-27" placeholder="Cm_Per_Pixelの値を入力" onChange={(e) => setCmPer(e.target.value)} />
          </Col>
          <Col xs={12} md={4}>
            <Form.Label>Att_Coef</Form.Label>
            <Form.Control type="text" name="att_coef" value={att_coef as string} className="w-27" placeholder="Att_Coefの値を入力" onChange={(e) => setAtt(e.target.value)} />
          </Col>
          <Col xs={12} md={4}>
            <Form.Label>Enabled（デフォルト: True）</Form.Label>
            <Form.Control as="select" className="w-27" value={enabled as string} onChange={e => setEnabled(e.target.value)} >
                <option value="true">True</option>
                <option value="false">False</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mx-3">
          <Col xs={12} md={4}>
            <Form.Label>Created_gt（データ作成時刻前）</Form.Label>
            <Form.Control type="datetime-local" step="1" name="created_gt" value={created_gt as string} className="w-27" placeholder="バッテリーログ到達時の時刻（<=）を入力 [YYYY-mm-dd hh:mm:ss]" onChange={(e) => changeCreatedGt(e.target.value)} />
          </Col>
          <Col xs={12} md={4}>
            <Form.Label>Created_lt（データ作成時刻後）</Form.Label>
            <Form.Control type="datetime-local" step="1" name="created_lt" value={created_lt as string} className="w-27" placeholder="バッテリーログ到達時の時刻（<=）を入力 [YYYY-mm-dd hh:mm:ss]" onChange={(e) => changeCreatedLt(e.target.value)} />
          </Col>
          <Col xs={12} md={4}>
            <Form.Label>Update_gt（データ更新時刻前）</Form.Label>
            <Form.Control type="datetime-local" step="1" name="update_gt" value={updated_gt as string} className="w-27" placeholder="バッテリーログ到達時の時刻（<=）を入力 [YYYY-mm-dd hh:mm:ss]" onChange={(e) => changeUpdateGt(e.target.value)} />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mx-3">
          <Col xs={12} md={4}>
            <Form.Label>Update_lt（データ更新時刻後）</Form.Label>
            <Form.Control type="datetime-local" step="1" name="update_lt" value={updated_lt as string} className="w-27" placeholder="バッテリーログ到達時の時刻（<=）を入力 [YYYY-mm-dd hh:mm:ss]" onChange={(e) => changeUpdateLt(e.target.value)} />
          </Col>
          <Col xs={12} md={4}>
            <Form.Label>Sort（デフォルト: DESC（新着順））</Form.Label>
            <Form.Control as="select" className="w-27" value={sort as string} onChange={e => setSort(e.target.value)} >
                <option value="">ASC（古い順）またはDESC（新着順）を指定</option>
                <option value="asc">ASC（古い順）</option>
                <option value="desc">DESC（新着順）</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <div className="text-center">
          <Form.Group as={Button} type="submit" className="mt-3 mb-2">View ClusterMaster</Form.Group>
        </div>
      </Form>
      <Form className="font-weight-bold">
        <div className="text-center">
          <Form.Group as={Button} type="button" className="mt-3 mb-2" onClick={(e) =>  createCSV(e)}>CSV Export</Form.Group>
          <div>
            <Form.Label>CSV出力の項目に含める</Form.Label> 
            <label>
              <input type="checkbox" className='mx-2' onChange={() => setNoCheck(prevState => !prevState)}></input>
            no</label>
          </div>
        </div>
      </Form>
    </div>
    {(() => {
      if (isLoading) {
        return (
          <div>
            <Loading/>
          </div>
        )
      }
    })()}

    {(() => {
    if (result.length > 0) {
        return  (
          <div>
            <p className="mx-4">TotalCount:{total}</p>
            <div className="d-flex justify-content-around">     
              <PageSelect pageNo={pageNo} lastPageNo={lastPageNo} getLog={getCluster}></PageSelect>  
            </div>  
            
            <Result result={result}></Result>
            
          </div>
        );
    } else {
        return (
          <div>
              {
                  total === 0 && <p className="mx-4">TotalCount:{total}</p> 
              }
          </div>
        );
    }
    
    })()}
    </div>
  )
}

export default ClusterMaster
import axios, { AxiosHeaderValue } from "axios";
import { useEffect, useState } from "react";
import deviceResultType from "../model/deviceResultType";
import { Button, Col, Form, Row } from "react-bootstrap";
import Result from "../components/DeviceLogResult";
import Header from "../components/Header";
import PageSelect from "../components/PageSelect";
import ExcelJS from 'exceljs';
import Loading from "../components/Loading";

const DeviceLog = () => {

    const [apiKey, setApikey] = useState<string>("");
    const [deviceid, setDeviceId] = useState<string>("");
    const [per_page, setPer_Page] = useState<string>("");
    const [os_type, setOs_Type] = useState<string>("");
    const [os_version, setOs_Version] = useState<string>("");
    const [sdk_version, setSdk_Version] = useState<string>("");
    const [model, setModel] = useState<string>("");
    const [bluetooth, setBlueTooth] = useState<string>("");
    const [location, setLocation] = useState<string>("");
    const [detected_gt, setDetcted_gt] = useState<string>("");
    const [detected_lt, setDetcted_lt] = useState<string>("");
    const [arrived_gt, setArrived_gt] = useState<string>("");
    const [arrived_lt, setArrived_lt] = useState<string>("");
    const [sort, setSort] = useState<string>("");
    const [pageNo, setPageNo] = useState<number>(0);
    const [lastPageNo, setLastPageNo] = useState<number>(0);
    const [total, setTotal] = useState<number>(-1);
    const [result, setResult] = useState<deviceResultType[]>([]);
    const results: deviceResultType[] = [];
    const [csvResult, setCSVResult] = useState<deviceResultType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isNoCheck, setNoCheck] = useState<boolean>(false);
    var apiUrl:string = "";

    const checkLocoEnv = () => {
      if (localStorage.getItem("locoEnv") === "prod") {
        apiUrl = "https://api.bc-loco.jp/api/device_logs"
      } else {
        apiUrl = "http://stg.api.bc-loco.jp/api/device_logs"
      }
    }
    
    const getDeviceLog = async(e: any, pageNo: number) => {
        e.preventDefault();
        saveParams();
        saveApikey();
        checkLocoEnv();
        await axios.get(apiUrl,
        {
          headers: {
              "Authorization": apiKey,
              "Content-Type": "application/json"
          },
          params: {
              ...(deviceid ? {device_id: [deviceid]} : {}),
              ...(pageNo ? {page: pageNo} : {}),
              ...(per_page ? {per_page: per_page} : {}),
              ...(os_type ? {os_type: os_type} : {}),
              ...(os_version ? {os_version: os_version} : {}),
              ...(sdk_version ? {sdk_version: sdk_version} : {}),
              ...(model ? {model: model} : {}),
              ...(bluetooth ? {bluetooth: bluetooth} : {}),
              ...(location ? {location: location} : {}),
              ...(detected_gt ? {detected_gt: detected_gt.replace("T", " ")} : {}),
              ...(detected_lt ? {detected_lt: detected_lt.replace("T", " ")} : {}),
              ...(arrived_gt ? {arrived_gt: arrived_gt.replace("T", " ")} : {}),
              ...(arrived_lt ? {arrived_lt: arrived_lt.replace("T", " ")} : {}),
              ...(sort ? {sort: sort} : {})
          }
        })
        .then(async res => {
          console.log(res);
          if(res.data.code === 400) {
            alert("条件設定に問題があります。" + JSON.stringify(res.data.payload))
            return
          } else if(res.data.code === 401) {
            alert("APIKeyが正しく入力されていません。" + JSON.stringify(res.data.message))
            return
          }
          const items = JSON.parse(JSON.stringify(res.data.payload))
          if (items.total > 100000) {
            alert("10万件を超えるデータは表示出来ません。 総件数:" + items.total + "\n日付範囲を調整して下さい。")
            return 
          }
          setTotal(items.total)
          setPageNo(items.current_page)
          setLastPageNo(items.last_page)
          
          items.data.forEach((value: deviceResultType, index: number) => {
            results.push(value)
            results[index].no = items.from;
            items.from++;
          })
          setResult(results)
        })
        .catch(err => 
          alert("エラーが発生しました。" + err)
        )
    }

    const createCSV = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {

      
      const workbook = new ExcelJS.Workbook();
      workbook.addWorksheet("deviceLog");
      const worksheet = workbook.getWorksheet("deviceLog");
      const columns: Partial<ExcelJS.Column>[] = [];

      if (isNoCheck) {
        columns.push({ header: "no", key: "no" }) 
      }
      columns.push({ header: "id", key: "id" })
      columns.push({ header: "bundle_id", key: "bundle_id" })
      columns.push({ header: "device_id", key: "device_id" }) 
      columns.push({ header: "os_type", key: "os_type" }) 
      columns.push({ header: "os_version", key: "os_version" }) 
      columns.push({ header: "sdk_version", key: "sdk_version" }) 
      columns.push({ header: "model", key: "model" }) 
      columns.push({ header: "bluetooth", key: "bluetooth" }) 
      columns.push({ header: "location", key: "location" }) 
      columns.push({ header: "metadata", key: "metadata" }) 
      columns.push({ header: "detected", key: "detected" }) 
      columns.push({ header: "arrived", key: "arrived" }) 

      worksheet.columns = columns;
      setIsLoading(true);
      if (await getAllDeviceLog(e, 1) == true ) {
      
        
        csvResult.forEach((value: deviceResultType) => {
          worksheet.addRow(value);
        });

        csvResult.length = 0;

        console.log("Row Count:" + worksheet.rowCount)
        setIsLoading(false);

        try {
          const uint8Array = await workbook.csv.writeBuffer();
          const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          setTimeout(()=> {
            a.href = url;
            a.download = "devicelog_" + getNowDate() +  ".csv";
            a.click();
            a.remove();
          },1000)

        } catch (error) {
          console.error(error);
        }
      } else {
        setIsLoading(false);
      }
     
    }

    const getAllDeviceLog = async(e: any, pageNo: number) => {
      let ret = true;
      e.preventDefault();
      checkLocoEnv();
      await axios.get(apiUrl,
      {
        headers: {
            "Authorization": apiKey,
            "Content-Type": "application/json"
        },
        params: {
          ...(deviceid ? {device_id: [deviceid]} : {}),
          ...(pageNo ? {page: pageNo} : {}),
          ...({per_page: 1000}),
          ...(os_type ? {os_type: os_type} : {}),
          ...(os_version ? {os_version: os_version} : {}),
          ...(sdk_version ? {sdk_version: sdk_version} : {}),
          ...(model ? {model: model} : {}),
          ...(bluetooth ? {bluetooth: bluetooth} : {}),
          ...(location ? {location: location} : {}),
          ...(detected_gt ? {detected_gt: detected_gt.replace("T", " ")} : {}),
          ...(detected_lt ? {detected_lt: detected_lt.replace("T", " ")} : {}),
          ...(arrived_gt ? {arrived_gt: arrived_gt.replace("T", " ")} : {}),
          ...(arrived_lt ? {arrived_lt: arrived_lt.replace("T", " ")} : {}),
          ...(sort ? {sort: sort} : {})
        }
      })
      .then(async res => {
        console.log(res);
        if(res.data.code === 400) {
          alert("条件設定に問題があります。" + JSON.stringify(res.data.payload))
          return ret = false
        } else if(res.data.code === 401) {
          alert("APIKeyが正しく入力されていません。" + JSON.stringify(res.data.message))
          return ret = false
        }
        const items = JSON.parse(JSON.stringify(res.data.payload))
        if (items.total > 100000) {
          alert("10万件を超えるデータは出力出来ません。 総件数:" + items.total + "\n日付範囲を調整して下さい。")
          return ret = false
        }
        let csvArrayCount = csvResult.length;
        
        items.data.forEach((value: deviceResultType, index: number) => {
          csvResult.push(value);
          csvResult[index + csvArrayCount].no = items.from;
          items.from++;
        })
        setCSVResult(csvResult);
        if (items.current_page != items.last_page) {
          await getAllDeviceLog(e, items.current_page + 1);
        } else {
          console.log("getall: " + csvResult.length);
        }
      })
      .catch(err => 
        alert("エラーが発生しました。" + err)
      )
      return ret
  }

  const changeDetectedGt = (value: string) => {
    if (value.length >= 1 && value.length < 17) {
      value = value + ":00";
    }
    setDetcted_gt(value);
  }

  const changeDetectedLt = (value: string) => {
    if (value.length >= 1 && value.length < 17) {
      value = value + ":00";
    }
    setDetcted_lt(value);
  }

  const changeArrivedGt = (value: string) => {
    if (value.length >= 1 && value.length < 17) {
      value = value + ":00";
    }
    setArrived_gt(value);
  }

  const changeArrivedLt = (value: string) => {
    if (value.length >= 1 && value.length < 17) {
      value = value + ":00";
    }
    setArrived_lt(value);
  }

  const getNowDate = () => {
    let dt = new Date();
    let y = dt.getFullYear();
    let m = ("00" + (dt.getMonth()+1)).slice(-2);
    let d = ("00" + (dt.getDate())).slice(-2);
    let result = y  + m +  d;
    return result;
  }

  type deviceParamsType = {
    deviceid?: string,
    perpage?: string,
    ostype?: string,
    osversion?: string,
    sdkversion?: string,
    model?: string,
    bluetooth?: string,
    location?: string,
    detectedgt?: string,
    detectedlt?: string,
    arrivedgt?: string,
    arrivedlt?: string,
    sort?: string
  }

  const saveParams = () => {
    let params: deviceParamsType = {};
    params.deviceid = deviceid ? deviceid : "";
    params.perpage = per_page ? per_page : "";
    params.ostype = os_type ? os_type : "";
    params.osversion = os_version ? os_version : "";
    params.sdkversion = sdk_version ? sdk_version : "";
    params.model = model ? model : "";
    params.bluetooth = bluetooth ? bluetooth : "";
    params.location = location ? location : "";
    params.detectedgt = detected_gt ? detected_gt : "";
    params.detectedlt = detected_lt ? detected_lt : "";
    params.arrivedgt = arrived_gt ? arrived_gt : "";
    params.arrivedlt = arrived_lt ? arrived_lt : "";
    params.sort = sort ? sort : "";

    let json = JSON.stringify(params);
    console.log("params:" + json);
    localStorage.setItem("locoDeviceParams", json)

  }

  const saveApikey = () => {
    localStorage.setItem("locoApikey", apiKey ? apiKey : "" );
  }

  const loadParams = () => {
    const paramsJson = localStorage.getItem("locoDeviceParams")
    if (paramsJson != null) {
      const params:deviceParamsType = JSON.parse(paramsJson as string)
      setDeviceId(params.deviceid ? params.deviceid : "");
      setPer_Page(params.perpage ? params.perpage : "")
      setOs_Type(params.ostype ? params.ostype : "");
      setOs_Version(params.osversion ? params.osversion : "");
      setSdk_Version(params.sdkversion ? params.sdkversion : "");
      setModel(params.model ? params.model : "");
      setBlueTooth(params.bluetooth ? params.bluetooth : "");
      setLocation(params.location ? params.location : "");
      setDetcted_gt(params.detectedgt ? params.detectedgt : "");
      setDetcted_lt(params.detectedlt ? params.detectedlt : "");
      setArrived_gt(params.arrivedgt ? params.arrivedgt : "");
      setArrived_lt(params.arrivedlt ? params.arrivedlt : "");
      setSort(params.sort ? params.sort : "");
    }
  }

  const loadApikey = () => {
    const locoApikey = localStorage.getItem("locoApikey")
    if (locoApikey != null) {
      setApikey(locoApikey ? locoApikey : "");
    }
  }

  const setSelectApikey = () => {
    const secretJson = localStorage.getItem("locoSecretArray")
    if (secretJson != null) {
      const secret = JSON.parse(secretJson as string)

      const selectApikey = document.getElementById("apikey") as HTMLSelectElement;
      const option1 = document.createElement("option");
      option1.value = "";
      option1.textContent = "APIKeyを選択して下さい";
      selectApikey?.appendChild(option1)

      secret.forEach((element: { apikey: string; name: string | null; }) => {
          const option1 = document.createElement("option");
          option1.value = element.apikey;
          option1.textContent = element.name;
          selectApikey?.appendChild(option1)
      })
    }
  }


  useEffect(() => {
    const secretJson = localStorage.getItem("locoSecretArray")
    if (secretJson != null) {
      setSelectApikey()
    }
    loadParams();
    loadApikey();
      
  },[])

  return (
      <div>
      <Header current_page="Device"/>
      <div className="card">
        <Form className="font-weight-bold" autoComplete="on" onSubmit={(e) => getDeviceLog(e, 0)}>
          
          <Form.Group as={Row} className="mx-3">
            <Col xs={12} md={4}>
              <Form.Label>ApiKey</Form.Label>
              <Form.Control as="select" id="apikey" value={apiKey as string} placeholder="ApiKeyを入力" onChange={(e) => setApikey(e.target.value)} />
            </Col>
            <Col xs={12} md={4}>
              <Form.Label>Device_Id</Form.Label>
              <Form.Control type="text" name="deviceId" value={deviceid as string} placeholder="Device_Idを入力" onChange={(e) => setDeviceId(e.target.value)} />
            </Col>
            <Col xs={12} md={4}>
              <Form.Label>Per_Page（デフォルト100件/最大1000件）</Form.Label>
              <Form.Control type="text" name="per_page" value={per_page as string}  placeholder="１ページ辺りの最大件数を指定" onChange={(e) => setPer_Page(e.target.value)} />
              </Col>
          </Form.Group>
            
          <Form.Group as={Row} className="mx-3">
            <Col xs={12} md={4}>
              <Form.Label>Os_Type</Form.Label>
              <Form.Control as="select" className="w-27" value={os_type as string} onChange={e => setOs_Type(e.target.value)} >
                  <option value="">Os_Type（iOSまたはAndroid）を指定</option>
                  <option value="iOS">iOS</option>
                  <option value="Android">Android</option>
              </Form.Control>
            </Col>

            <Col xs={12} md={4}>
                <Form.Label>Os_Version</Form.Label>
                <Form.Control type="text" name="os_version" value={os_version as string} className="w-27" placeholder="Os_Versionを入力" onChange={(e) => setOs_Version(e.target.value)} />
            </Col>
            <Col xs={12} md={4}>
                <Form.Label>Sdk_Version</Form.Label>
                <Form.Control type="text" name="sdk_version" value={sdk_version as string} className="w-27" placeholder="Sdk_Versionを入力" onChange={(e) => setSdk_Version(e.target.value)} />
            </Col>
          </Form.Group>
          
          <Form.Group as={Row} className="mx-3">
            <Col xs={12} md={4}>
              <Form.Label>Model</Form.Label>
              <Form.Control type="text" name="model" value={model as string} className="w-27" placeholder="Modelを入力" onChange={(e) => setModel(e.target.value)} />
            </Col>
            <Col xs={12} md={4}> 
              <Form.Label>BlueTooth</Form.Label>
              <Form.Control as="select" className="w-27" value={bluetooth as string} onChange={e => setBlueTooth(e.target.value)} >
                  <option value="">True（ON）またはFalse（OFF）を指定</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
              </Form.Control>
            </Col>
            <Col xs={12} md={4}> 
              <Form.Label>Location</Form.Label>
              <Form.Control as="select" className="w-27" value={location as string} onChange={e => setLocation(e.target.value)} >
                  <option value="">True（ON）またはFalse（OFF）を指定</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mx-3">
            <Col xs={12} md={4}> 
              <Form.Label>Detected_gt（デバイスログ生成時刻以降）</Form.Label>
              <Form.Control type="datetime-local" step="1" name="detected_gt" value={detected_gt as string} className="w-27" placeholder="デバイスログ生成の時刻（>=）を入力 [YYYY-mm-dd hh:mm:ss]" onChange={(e) => changeDetectedGt(e.target.value)} />
            </Col>
            <Col xs={12} md={4}> 
              <Form.Label>Detected_lt（デバイスログ生成時刻以前）</Form.Label>
              <Form.Control type="datetime-local" step="1" name="detected_lt" value={detected_lt as string} className="w-27" placeholder="デバイスログ生成の時刻（<=）を入力 [YYYY-mm-dd hh:mm:ss]" onChange={(e) => changeDetectedLt(e.target.value)} />
            </Col>
            <Col xs={12} md={4}> 
              <Form.Label>Arrived_gt（ログのサーバー到達時刻以降）</Form.Label>
              <Form.Control type="datetime-local" step="1" name="arrived_gt" value={arrived_gt as string} className="w-27" placeholder="デバイスログ到達時の時刻（>=）を入力 [YYYY-mm-dd hh:mm:ss]" onChange={(e) => changeArrivedGt(e.target.value)} />
            </Col>
          </Form.Group>
          
          <Form.Group as={Row} className="mx-3">
            <Col xs={12} md={4}> 
              <Form.Label>Arrived_lt（ログのサーバー到達時刻以前）</Form.Label>
              <Form.Control type="datetime-local" step="1" name="arrived_lt" value={arrived_lt as string} className="w-27" placeholder="デバイスログ到達時の時刻（<=）を入力 [YYYY-mm-dd hh:mm:ss]" onChange={(e) => changeArrivedLt(e.target.value)} />
            </Col>
            <Col xs={12} md={4}> 
              <Form.Label>Sort（デフォルト: DESC（新着順））</Form.Label>
              <Form.Control as="select" className="w-27" value={sort as string} onChange={e => setSort(e.target.value)} >
                  <option value="">ASC（古い順）またはDESC（新着順）を指定</option>
                  <option value="asc">ASC（古い順）</option>
                  <option value="desc">DESC（新着順）</option>
              </Form.Control>
            </Col>
          </Form.Group>
      
          <div className="text-center">
            <Form.Group as={Button} type="submit" className="mt-3 mb-2">View DeviceLogs</Form.Group>
          </div>
        </Form>
        <Form className="font-weight-bold">
          <div className="text-center">
            <Form.Group as={Button} type="button" className="mt-3 mb-2" onClick={(e) =>  createCSV(e)}>CSV Export</Form.Group>
            <div>
              <Form.Label>CSV出力の項目に含める</Form.Label> 
              <label>
                <input type="checkbox" className='mx-2' onChange={() => setNoCheck(prevState => !prevState)}></input>
              no</label>
            </div>
          </div>
        </Form>
      </div>
      {(() => {
          if (isLoading) {
            return (
              <div>
                <Loading/>
              </div>
            )
          }
        
      })()}

      {(() => {
      if (result.length > 0) {
          return  (
            <div>
              <p className="mx-4">TotalLogCount:{total}</p>
              <div className="d-flex justify-content-around">     
                <PageSelect pageNo={pageNo} lastPageNo={lastPageNo} getLog={getDeviceLog}></PageSelect>  
              </div>  
              
              <Result result={result}></Result>
              
            </div>
          );
      } else {
        return (
          <div>
              {
                  total === 0 && <p className="mx-4">TotalLogCount:{total}</p> 
              }
          </div>
          );
      }
      
      })()}
    </div>
  )
}

export default DeviceLog